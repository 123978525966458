<template>
  <figure :key="sourceId" v-lazyload class="o-thumb figure" :class="{ 'c-pointer': !!source }">
    <span v-if="!source" class="text-light"><i class="fas fa-camera"></i></span>
    <b-img v-if="source" :data-url="source" class="figure-img rounded" />
    <div class="actions-thumb">
      <b-button v-if="source && externalopen != null" variant="light" :href="source" class="text-info py-0" target="_blank">
        <i class="fas fa-external-link-alt"></i>
      </b-button>
      <b-button v-if="deleteData != null" variant="light" class="text-danger py-0" @click.prevent="deletePicture">
        <i class="fa fa-trash-alt"></i>
      </b-button>
    </div>
  </figure>
</template>

<script>
import utils from '@/utils'

export default {
  name: 'Thumb',
  props: {
    source: {
      type: String
    },
    deleteData: {
      type: Object,
      required: false,
      default: null
    },
    externalopen: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  data () {
    return {
      sourceId: 0
    }
  },
  watch: {
    source: function () {
      this.sourceId += 1
    }
  },
  methods: {
    deletePicture () {
      this.$bvModal.msgBoxConfirm(`Êtes-vous sûr de vouloir supprimer l'image ?`, utils.confirmParams)
        .then(value => value && this.$emit('deletePicture', this.deleteData))
    }
  }
}
</script>

<style scoped>
  .o-thumb {
    display: inline-flex;
    overflow: hidden;
    line-height: 32px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin: 0;
    border-radius: 2px;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
  }
  .o-thumb.loaded {
    background-color: #fff;
  }
  .o-thumb img {
    max-width: 30px;
    max-height: 30px;
    vertical-align: top;
    border-radius: 3px;
    padding: 0;
    margin: 0;
  }
  .o-thumb .actions-thumb {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2px;
    font-size: 1.4rem;
    line-height: 16px;
    display: none;
  }
  .o-thumb .actions-thumb button {
    min-width: 36px;
    margin: 1px 0;
  }
  .o-thumb:hover .actions-thumb { display: block; }

  .o-thumb.xxs {
    line-height: 20px;
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  .o-thumb.xxs img {
    max-width: 20px;
    max-height: 20px;
  }

  .o-thumb.xs {
    line-height: 24px;
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  .o-thumb.xs img {
    max-width: 24px;
    max-height: 24px;
  }

  .o-thumb.md {
    line-height: 48px;
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
  .o-thumb.md img {
    max-width: 48px;
    max-height: 48px;
  }

  .o-thumb.x-big {
    line-height: 54px;
    width: 54px;
    min-width: 54px;
    height: 54px;
  }
  .o-thumb.x-big img {
    max-width: 54px;
    max-height: 54px;
  }

  .o-thumb.big {
    line-height: 64px;
    width: 64px;
    min-width: 64px;
    height: 64px;
  }
  .o-thumb.big img {
    max-width: 64px;
    max-height: 64px;
  }

  .o-thumb.x-large {
    line-height: 180px;
    width: 180px;
    min-width: 180px;
    height: 180px;
  }
  .o-thumb.x-large img {
    max-width: 172px;
    max-height: 172px;
  }
  .o-thumb.large {
    line-height: 240px;
    width: 240px;
    min-width: 240px;
    height: 240px;
  }
  .o-thumb.large img {
    max-width: 232px;
    max-height: 232px;
  }
  .o-thumb.auto {
    width: 100%;
    max-width: 360px;
    height: auto;
  }
  .o-thumb.auto img {
    max-width: 100%;
    max-height: 100%;
  }

  .o-thumb.popover-thumb-figure {
    line-height: 140px;
    width: 140px;
    min-width: 140px;
    height: 140px;
  }
  .o-thumb.popover-thumb-figure img {
    max-width: 140px;
    max-height: 140px;
  }
</style>
